<template>
    <div
        v-show="visible && errors && errors.length"
        :id="id"
        class="lni-u-pb3"
        role="alert">
        <p
            v-if="heading.length > 0"
            class="lni-u-heading--3">
            {{ heading }}
        </p>
        <p
            v-if="message.length > 0"
            class="lni-u-type--xs">
            {{ message }}
        </p>

        <div
            v-for="(item, index) in customMessages"
            :key="index">
            <span class="lni-c-alert--error --global lni-u-ph1">
                <span
                    :aria-hidden="true"
                    class="lnicon--exclamation"></span>
                {{ item }}
            </span>
        </div>

        <div
            v-for="(error, index) in errors"
            :key="error.id"
            class="lni-u-mt1">
            <a
                v-scroll-to="'#' + error.id"
                href="#"
                class="lni-c-alert--error --global lni-u-ph1"
                @click="setFocus(error.id)">
                <span>
                    <span
                        :id="`${id}_component-error_${index}`"
                        aria-hidden="true"
                        class="lnicon--exclamation"></span>{{ error.global }}
                </span>
            </a>
        </div>
    </div>
</template>

<script>
import text from '@gov.wa.lni/framework.one-lni.core/source/lib/getText.js';

export default {
    name: 'lni-error-container',
    mounted() {
        const self = this;

        if (this.heading.length === 0) {
            this.setHeading(text(this.lang, 'lni-error-container').heading);
        }
        if (this.message.length === 0) {
            this.setMessage(text(this.lang, 'lni-error-container').message);
        }

        this.$watch('lang', newValue => {
            self.setHeading(text(newValue, 'lni-error-container').heading);
            self.setMessage(text(newValue, 'lni-error-container').message);
        });
    },
    methods: {
        setHeading(heading) {
            this.$store.commit('setAttribute', {
                id: this.id,
                attribute: 'heading',
                value: heading,
            });
        },
        setMessage(message) {
            this.$store.commit('setAttribute', {
                id: this.id,
                attribute: 'message',
                value: message,
            });
        },
        setFocus(id) {
            const target = document.getElementById(id);
            if (target.tabIndex < 0 && !target.hasAttribute('tabindex')) {
                // element is not focusable
                target.setAttribute('tabindex', '-1');
                target.focus();
                this.$nextTick(() => target.removeAttribute('tabindex'));
            } else {
                target.focus();
            }
        },
    },
}; </script>