<template>
    <div
        :id="id"
        role="combobox"
        :aria-owns="`${id}_suggestions_list-box`"
        :class="{
            [`--custom-width-${customWidth}`]: customWidth,
            'lni-u-full-width':fullWidth
        }"
        :aria-expanded="`${showItems}`"
    >
        <lni-input-search
            v-if="isSearch"
            :id="`${id}_text`"
            :ref="`${id}_text`"
            :placeholder="placeholder"
            :name="name"
            :classString="classString"
            :searchAction="enterAction"
            :clearAction="[`${id}/clear`, clearAction]"
            :inputAction="`${id}/syncFromInnerComponentSearch`"
            :pasteAction="pasteAction"
            :required="required"
            autocomplete="off"
            @keydown="onKeydown"
            @blur="onBlur"
            @focus="onFocus"
        />
        <lni-input-text
            v-else
            :id="`${id}_text`"
            :ref="`${id}_text`"
            :labelText="labelText"
            :name="name"
            :classString="classString"
            :clearAction="clearAction"
            :inputAction="`${id}/syncFromInnerComponent`"
            :pasteAction="pasteAction"
            :required="required"
            :hasInlineMessages="false"
            autocomplete="off"
            @keydown="onKeydown"
            @blur="onBlur"
            @focus="onFocus"
        />
        <div
            v-show="suggestions.length"
            :id="`${id}_suggestions-container`"
            ref="suggestionsContainer"
            class="lni-c-autocomplete__suggestion-wrapper"
            @keydown.tab="blurSelect"
        >
            <ul
                :id="`${id}_suggestions-list-box`"
                role="listbox"
                :aria-describedby="`${id}_status ${id}_instructions`"
                :class="['lni-u-list-reset',
                         'lni-c-autocomplete__suggestion-box',
                         {'--open': showItems}]"
            >
                <li
                    v-for="(suggestion, index) in suggestions"
                    :key="suggestion.key"
                    role="option"
                    :aria-selected="`${focusIndex === index}`"
                    class="lni-c-autocomplete__suggestion lni-u-line-height--tight lni-u-pa1"
                >
                    <button
                        :id="`${id}_suggestion_${index}`"
                        :ref="`${id}suggestion`"
                        tabIndex="-1"
                        class="lni-u-full-width lni-u-button-reset lni-c-autocomplete__suggestion-button"
                        @mousedown.prevent="onSelect(suggestion)"
                        @keydown="onMove"
                        @keydown.enter.prevent="onSelect(suggestion)"
                        @keydown.escape.prevent="clear"
                        @blur="onBlur"
                        @focus="onFocus"
                    >
                        <lni-html-element
                            :id="`${id}_suggestion_${index}--text`"
                            element="span"
                        />
                    </button>
                </li>
            </ul>
            <div
                :id="`${id}_status`"
                aria-live="polite"
                role="status"
                class="lni-u-visually-hidden"
            >
                {{ statusMessage }}<br>
            </div>
            <p
                v-if="suggestions.length"
                :id="`${id}_instructions`"
                class="lni-u-visually-hidden"
            >
                {{ instructions }}
            </p>
        </div>
        <div
            v-if="hasInlineMessages && (hasHelperText || hasErrorText)"
            :id="`${id}_helper-text`"
            class="lni-c-text-field__helper-text lni-u-type--xxs lni-u-line-height--tight"
        >
            <template v-if="!hasErrorText || persistHelperText">
                <slot name="helperText" />
            </template>
            <template v-if="hasErrorText">
                <p
                    :id="`${id}_error-text`"
                    class="lni-c-text-field__error-message"
                >
                    {{ errorText }}
                </p>
            </template>
        </div>
    </div>
</template>

<script>
import interpolate from '@gov.wa.lni/framework.one-lni.core/source/lib/interpolate.js';

export default {
    name: 'LniAutoSuggestion',
    data() {
        return {
            initial: true,
            focusIndex: -1,
            textFocus: true,
            minimumSuggestedCharacters: 1,
            suggestions: [],
            conditionallyShowAll: false,
        };
    },
    computed: {
        activeDescendant() {
            if (this.focusIndex === -1) {
                return '';
            }

            return this.$refs[this.id + 'suggestion'][this.focusIndex].id;
        },
        showItems() {
            return ((!this.initial && (this.value.length >= this.minimumSuggestedCharacters)) || this.conditionallyShowAll)
                && this.suggestions.length >= 1
                && this.textFocus === true;
        },
        statusMessage() {
            const count = this.suggestions.length;
            const many = 2;
            const message = count < many ? this.statusMessages[count] : this.statusMessages[many];

            return interpolate(message, {
                count,
            });
        },
        hasErrorText() {
            return !!this.$store.state[this.id].errorText;
        },
        hasHelperText() {
            return !!this.$slots.helperText;
        },
    },
    mounted() {
        const storeState = this.$store.state[this.id];
        const valueMismatch = {
            global: storeState.messages.valueMismatch.global(storeState),
            inline: storeState.messages.valueMismatch.inline(),
        };
        this.$store.commit(`${this.id}_text/addValidityTest`, {
            validityTest: 'valueMismatch',
            global: () => valueMismatch.global,
            inline: () => valueMismatch.inline,
        }, {
            root: true,
        });
        this.updateValidity();

        this.$watch('value', () =>  {
            this.conditionallyShowAll = false;
            this.updateSuggestions();
        });

        this.$watch('cleared', cleared => {
            if (cleared === true) {
                this.$refs[`${this.id}_text`].$refs.searchInput.focus();
                this.cleared = false;
            }
        });
        this.$emit('mounted');
    },
    methods: {
        copyValidityState(validityState) {
            const validityStore = {};
            for (let type in validityState) {
                if (validityState) {
                    validityStore[type] = validityState[type];
                }
            }
            return validityStore;
        },
        updateValidity() {
            const sourceValidity = this.copyValidityState(this.validity);
            const targetState = this.$store.state[`${this.id}_text`];
            const targetValidity = this.copyValidityState(targetState.validity);
            const targetRequired = targetState.required;

            let mismatch,
                missing = false;

            if (this.value.length && !this.options.find(opt => opt.value.toLowerCase() === this.value.toLowerCase())) {
                mismatch = true;
            }

            if (!this.value.length && targetRequired) {
                missing = true;
            }

            sourceValidity.valueMismatch = mismatch;
            targetValidity.valueMismatch = mismatch;
            sourceValidity.valueMissing = missing;
            targetValidity.valueMissing = missing;

            this.$store.dispatch('updateValidity', {
                targetId: this.id,
                validity: sourceValidity,
            }).then(() => {
                if (!this.initial) {
                    this.$store.dispatch('updateValidity', {
                        targetId: `${this.id}_text`,
                        validity: targetValidity,
                    }).then(() => {
                        this.$store.dispatch('validate', {
                            targetId: `${this.id}_text`,
                            validation: {},
                        });

                        this.$store.dispatch('validate', {
                            targetId: this.id,
                            validation: {},
                        });
                    });
                }
            });
        },
        clear() {
            this.conditionallyShowAll = false;
            this.$store.dispatch(`${this.id}/clear`).then(() => {
                this.updateValidity();
            });
        },
        getHighlightedText(text) {
            const regex = new RegExp('(' + this.value + ')', 'ig');
            return text.replace(regex, `<span class="--highlight">$1</span>`);
        },
        updateSuggestions() {
            let newFilteredSuggestions = [];
            if (this.suggestions.map(item => item.text).includes(this.value)) {
                newFilteredSuggestions = [];
            } else if (this.options && this.value.length >= this.minimumSuggestedCharacters) {
                newFilteredSuggestions = this.options;
                let query = this.value.split(' ');

                if (query.length >= 1) {
                    if (this.clientSideFiltering) {
                        let results = [];
                        let index = 0;
                        query.forEach(term => {
                            this.options.forEach(item => {
                                const words = item.text.split(' ');
                                words.forEach(word => {
                                    const result = word.replace(/[[({})\]]/g, '').toLowerCase().substring(0, term.length);
                                    if (result === term.toLowerCase()) {
                                        this.$set(results, index, item);
                                        index++;
                                    }
                                });
                            });
                        });
                        newFilteredSuggestions = results.slice(0, this.numberReturned);
                    }
                } else {
                    newFilteredSuggestions = [];
                }
            } else if (this.options
                && this.value.length < this.minimumSuggestedCharacters) {
                newFilteredSuggestions = [];
            }

            this.suggestions = this.conditionallyShowAll && !newFilteredSuggestions.length
                ? this.options.slice(0, this.numberReturned)
                : this.suggestions = newFilteredSuggestions;

            const self = this;
            this.$nextTick(() => {
                self.suggestions.forEach((item, index) => {
                    self.$store.commit('setAttribute', {
                        id: `${self.id}_suggestion_${index}--text`,
                        attribute: 'htmlString',
                        value: self.getHighlightedText(item.text),
                    }, {
                        root: true,
                    });
                });
            });
        },
        setActiveDescendant() {
            this.$store.commit(`setAttribute`, {
                id: `${this.id}_text`,
                attribute: 'ariaActivedescendant',
                value: this.activeDescendant,
            });
        },
        moveToSelect(val) {
            if (this.options.length > 0
                && (this.value.length >= this.minimumSuggestedCharacters || this.conditionallyShowAll)) {
                let indexToSelect = 0;
                if (val.key === 'ArrowUp') {
                    indexToSelect = this.$refs[this.id + 'suggestion'].length - 1;
                }

                this.$nextTick(() => {
                    this.$refs[this.id + 'suggestion'][indexToSelect].focus();
                });
                this.focusIndex = indexToSelect;
                this.setActiveDescendant();
            }
        },
        onSelect(suggestion) {
            this.value = suggestion.text;
            let actionName = this.isSearch ? 'syncToInnerComponentSearch' : 'syncToInnerComponent';
            this.$store.dispatch(`${this.id}/${actionName}`, {
                sourceId: this.id,
                targetId: `${this.id}_text`,
            }).then(() => {
                this.dispatchEvent('enterAction').then(() => {
                    this.updateValidity();
                });
            });
        },
        onMove(val) {
            if (this.options && this.suggestions.length >= 1) {
                if (val.key === 'ArrowUp' || val.key === 'ArrowDown') {
                    let btn = this.$refs[this.id + 'suggestion'];
                    val.preventDefault();
                    this.$nextTick(() => {
                        if (val.key === 'ArrowUp') {
                            if (this.focusIndex <= 0) {
                                this.focusIndex = btn.length - 1;
                            } else {
                                this.focusIndex--;
                            }

                        } else if (val.key === 'ArrowDown') {
                            if (this.focusIndex === btn.length - 1) {
                                this.focusIndex = 0;
                            } else {
                                this.focusIndex++;
                            }
                        }
                        btn[this.focusIndex].focus({
                            preventScroll: true,
                        });
                        this.setActiveDescendant();
                    });
                } else {
                    this.setInputFocus();
                }
            }
        },
        onFocus() {
            this.textFocus = true;
            this.initial = false;
        },
        blurSelect() {
            this.textFocus = false;
        },
        onBlur(e) {
            const parent = this.$refs.suggestionsContainer.parentElement;
            const leavingParent = !parent.contains(e.relatedTarget);
            if (leavingParent) {
                this.textFocus = false;
            }

            this.updateValidity();
        },
        onKeydown($event) {
            switch ($event.key) {
                case 'ArrowDown':
                    this.conditionallyShowAll = true;
                    this.updateSuggestions();
                    $event.preventDefault();
                    this.$nextTick(() => {
                        this.moveToSelect($event);
                    });
                    break;
                case 'ArrowUp':
                    this.moveToSelect($event);
                    break;
                case 'Escape':
                    this.clear();
                    break;
                case 'Enter':
                    $event.preventDefault();
                    break;
                default:
                    break;
            }
        },
        setInputFocus() {
            if (this.isSearch) {
                this.$refs[`${this.id}_text`].$refs.searchInput.focus();
            } else {
                this.$refs[`${this.id}_text`].$refs.input.focus();
            }
        },
    },
}; </script>