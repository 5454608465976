<template>
    <component
        :is="isFieldset ? 'fieldset' : 'div'"
        :id="id"
        class="lni-c-input-address"
        @focus="focusOnInput"
        @focusout="onFocusout"
        @focusin="onFocusin"
        @input="onInput"
        @change="onChange">
        <legend
            v-if="isFieldset"
            :id="`${id}_legend`"
            class="lni-c-input-address__legend lni-u-heading--4"
            :class="{'lni-u-font-color--error': hasErrorText}">
            {{ labelText }}
            <span
                v-if="hasErrorText"
                aria-hidden="true"
                class="lnicon--exclamation"></span>
        </legend>
        <div class="lni-u-flex lni-u-flex-column">
            <div>
                <lni-input-text
                    :id="`${id}_address1`"
                    ref="address1"
                    :filled="filled"
                    :dense="dense"
                    :aria-describedby="`${id}_legend`"
                    class="lni-u-full-width"
                    :labelText="label(this).address1"
                    :labelBefore="labelBefore"
                    :value="address1"
                    :inputAction="[`${id}/updateAddress1`]"
                    :hasInlineMessages="false"
                    :maxlength="address1MaxLength"
                    :required="required">
                </lni-input-text>
            </div>
            <lni-input-text
                :id="`${id}_address2`"
                ref="address2"
                :filled="filled"
                :dense="dense"
                aria-describedby="descriptionLabel"
                class="lni-u-full-width"
                :labelText="label(this).address2"
                :labelBefore="labelBefore"
                :value="address2"
                :inputAction="[`${id}/updateAddress2`]"
                :maxlength="address2MaxLength"
                :hasInlineMessages="false">
            </lni-input-text>
            <div class="lni-c-input-address__last-line-wrapper">
                <lni-input-text
                    :id="`${id}_city`"
                    ref="city"
                    class="lni-c-input-address__city lni-u-mr1"
                    :filled="filled"
                    :dense="dense"
                    aria-describedby="descriptionLabel"
                    :labelText="label(this).city"
                    :labelBefore="labelBefore"
                    :value="city"
                    :inputAction="[`${id}/updateCity`]"
                    :hasInlineMessages="false"
                    :maxlength="cityMaxLength"
                    :required="required">
                </lni-input-text>
                <lni-select
                    :id="`${id}_state`"
                    ref="state"
                    :filled="filled"
                    :dense="dense"
                    aria-describedby="descriptionLabel"
                    :labelText="label(this).state"
                    :labelBefore="labelBefore"
                    :options="stateOptions"
                    :value="state"
                    :changeAction="[`${id}/updateState`]"
                    :customWidth="3"
                    :hasInlineMessages="false"
                    :required="required">
                </lni-select>
                <lni-input-text
                    :id="`${id}_zip`"
                    ref="zip"
                    v-constrain="upTo10DigitsDashes"
                    :filled="filled"
                    :dense="dense"
                    :labelText="label(this).zip"
                    :labelBefore="isNotEnglish"
                    aria-describedby="descriptionLabel"
                    :value="zip"
                    :inputAction="[`${id}/updateZip`]"
                    :customWidth="7"
                    maxlength="10"
                    :required="required"
                    :pattern="zipPattern"
                    :hasInlineMessages="false">
                </lni-input-text>
            </div>
            <lni-select
                v-if="countyOptions.length"
                :id="`${id}_county`"
                ref="county"
                :filled="filled"
                :dense="dense"
                aria-describedby="descriptionLabel"
                :labelText="label(this).county"
                :labelBefore="labelBefore"
                :options="countyOptions"
                :value="county"
                :changeAction="[`${id}/updateCounty`]"
                :hasInlineMessages="false"
                class="lni-u-full-width"
                :required="required">
            </lni-select>
        </div>
        <div
            v-if="hasInlineMessages || hasHelperText"
            :id="`${id}_helper-text`"
            :class="hasErrorText ? 'lni-c-text-field__error-message --has-error' : null"
            class="lni-c-text-field__helper-text lni-u-type--xxs lni-u-line-height--tight"
            aria-live="polite">
            <template v-if="hasErrorText">
                <p>{{ errorText }}</p>
            </template>
            <template v-else>
                <slot name="helperText"></slot>
            </template>
        </div>
    </component>
</template>

<script>
import statesUs from '@gov.wa.lni/component.lni-input-address/states.json';
import constrain from '@gov.wa.lni/framework.one-lni.directives/source/constrain.js';

export default {
    name: 'lni-input-address',
    directives: {
        constrain,
    },
    data() {
        return {
            stateOptions: statesUs,
            hasFocus: false,
            upTo10DigitsDashes: /^[\d-]{0,10}$/,
        };
    },
    computed: {
        hasErrorText() {
            return !!this.$store.state[this.id].errorText;
        },
        hasHelperText() {
            return !!this.$slots.helperText;
        },
        isNotEnglish() {
            return Boolean(this.lang ? this.lang : this.$oneLni.getLanguage() === 'es');
        },
    },
    watch: {
        hasFocus(val) {
            if ( !val ) {
                //focus has left
                this.$emit('blur');
            }
        },
    },
    mounted() {
        this.setHasFocus();
        this.updateValidity();
    },
    methods: {
        /* Capture events from internal components */
        onFocusout() {
            this.setHasFocus();
        },
        onFocusin() {
            this.setHasFocus();
        },
        focusOnInput() {
            this.$refs.address1.$refs.input.focus();
        },
        onInput() {
            this.updateValidity();
            this.dispatchEvent('inputAction');
        },
        onChange() {
            this.updateValidity();
            this.dispatchEvent('changeAction');
        },
        validate() {
            this.$store.dispatch(`${this.id}/customValidate`, {
                targetId: this.id,
            });
        },
        updateValidity() {
            return this.$store.dispatch('updateValidity', {
                targetId: this.id,
            });
        },
        setHasFocus() {
            const container = this.$el;

            setTimeout(container => {
                const focused = document.activeElement;
                if ( container.contains(focused)) {
                    this.hasFocus = true;
                } else {
                    this.hasFocus = false;
                }
            }, 1, container);
        },
    },
}; </script>