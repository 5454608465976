<template>
    <div>
        <vue-recaptcha
            ref="recaptcha"
            :class="classString"
            sitekey="6LdlHR8UAAAAAPU3zBKQujd7qTEnPa3sPt9LbPv_"
            :size="size"
            @verify="onVerify"
            @expired="onExpired">
        </vue-recaptcha>
    </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha';

export default {
    name: 'lni-recaptcha',
    components: {
        'vue-recaptcha': VueRecaptcha,
    },
    mounted() {
        this.$watch('forceReset', force => {
            if (force) {
                this.reset();
                this.forceReset = false;
            }
        });
    },
    methods: {
        onVerify() {
            this.dispatchEvent('verifyAction');
        },
        onExpired() {
            this.reset();
            this.dispatchEvent('expireAction');
        },
        reset() {
            this.$refs.recaptcha.reset();
        },
    },
}; </script>